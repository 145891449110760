(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name statistics.entities.controller:EntitiesCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.statistics.entities')
    .controller('StatsEntitiesCtrl', StatsEntitiesCtrl);

  function StatsEntitiesCtrl($mdToast, $filter, categories, genders, entities, seasons, currentSeason, LicenceStatistics, TeamStatistics, programmes) {
    var vm = this;
    vm.ctrlName = 'StatsEntitiesCtrl';

    var personGenders = _.filter(genders, 'person', true);

    vm.licenceStats = undefined;
    vm.teamStats = undefined;

    vm.seasons = seasons;
    vm.entities = entities;
    vm.programmes = programmes;

    vm.selectedSeasonId = currentSeason.id;
    vm.selectedEntityId = undefined;
    vm.selectedProgramme = undefined;

    vm.myChartObjectPlayers = {
      type: 'Table',
      displayed: false,
      data: {
        cols: buildCols(categories, personGenders),
        rows: buildRows([], categories, personGenders)
      },
      options: {
        title: $filter('translate')('STATISTICS.PLAYER_LICENSES'),
        cssClassNames : {'headerCell': 'header-padding'}
      },
      formatters: {}
    };

    vm.myChartObjectNoPlayers = {
      type: 'Table',
      displayed: false,
      data: {
        cols: buildCols(categories, personGenders),
        rows: buildRows([], categories, personGenders)
      },
      options: {
        title: $filter('translate')('STATISTICS.NO_PLAYER_LICENSES'),
        cssClassNames : {'headerCell': 'header-padding'}
      },
      formatters: {}
    };


    vm.myChartObjectTeams = {
      type: 'Table',
      displayed: false,
      data: {
        cols: buildCols(categories, genders),
        rows: buildRows([], categories, genders)
      },
      options: {
        title: $filter('translate')('STATISTICS.ENTITY_TEAMS'),
        cssClassNames : {'headerCell': 'header-padding'}
      },
      formatters: {}
    };

    vm.changeMandatoryFilter = function () {
      if (_.isUndefined(vm.selectedSeasonId)) return;
      if (_.isUndefined(vm.selectedEntityId)) return;
      LicenceStatistics.query({'season_id': vm.selectedSeasonId, 'entity_id': vm.selectedEntityId}, function (data) {
        vm.licenceStats = data;
        TeamStatistics.query({'season_id': vm.selectedSeasonId, 'entity_id': vm.selectedEntityId}, function (data) {
          vm.teamStats = data;
          vm.changeFilter();
        }, function (err) {
          var message = err.data.message || 'ERROR_UPDATE';
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')(message))
              .position('bottom left')
              .hideDelay(3000)
          );
        });
      }, function (err) {
        var message = err.data.message || 'ERROR_UPDATE';
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')(message))
            .position('bottom left')
            .hideDelay(3000)
        );
      });
    };

    vm.changeFilter = function () {
      var preFilterParams = {};
      if (!_.isUndefined(vm.selectedProgramme)) preFilterParams['programme'] = vm.selectedProgramme;
      var preFilteredLicenceStats = _.filter(vm.licenceStats, preFilterParams);
      var filteredTeamStats = _.filter(vm.teamStats, preFilterParams);

      var filterParams = {};
      filterParams['licenceType'] = 'ATHLETE';
      var filteredLicenceStats = _.filter(preFilteredLicenceStats, filterParams);
      vm.myChartObjectPlayers.data.rows = buildRows(filteredLicenceStats, categories, personGenders);

      filteredLicenceStats = _.cloneDeep(preFilteredLicenceStats);
      _.remove(filteredLicenceStats, filterParams);
      vm.myChartObjectNoPlayers.data.rows = buildRows(filteredLicenceStats, categories, personGenders);

      vm.myChartObjectTeams.data.rows = buildRows(filteredTeamStats, categories, genders);
    };

    function buildRows(data, categories, personGenders) {

      var rows = [];

      var sports = _.uniq(_.pluck(data, 'sport'));

      _.forEach(sports, function (s) {
        var sportName = $filter('translate')('SPORTS.' + s);
        var row = buildRow(sportName, categories, personGenders, _.filter(data, 'sport', s));
        rows.push(row);
      });
      var totalName = $filter('translate')('TOTAL');
      var totalRow = buildRow(totalName, categories, personGenders, data);

      rows.push(totalRow);
      return rows;
    }

    function buildRow(rowName, categories, personGenders, data) {
      var values = [];
      values.push({v: rowName});

      var total = 0;

      _.forEach(categories, function (c) {
        _.forEach(personGenders, function (g) {

          var predicate = {'category': c.name, 'gender': g.name};
          var licences = _.filter(data, predicate);
          var numLicenceIds = _.size(_.uniq(_.pluck(licences, 'licenceId')));

          values.push({v: numLicenceIds});
          total = total + numLicenceIds;
        })
      });
      values.push({v: total});
      return {c: values};
    }

    function buildCols(categories, personGenders) {
      var cols = [];

      cols.push({id: 'sport', label: $filter('translate')('SPORT'), type: 'string'});

      _.forEach(categories, function (c) {
        _.forEach(personGenders, function (g) {
          var l = $filter('translate')('CATEGORIES.' + c.name) + ' ' + $filter('translate')(g.name);
          cols.push({id: c.name + '_' + g.name, label: l, type: 'number'});
        })
      });

      cols.push({id: 'total', label: $filter('translate')('TOTAL'), type: 'number'});
      return cols;
    }

    vm.readyPlayerHandler = function (chartWrapper) {
      vm.htmlPlayerTable = chartWrapper.getChart().getContainer().childNodes[0].childNodes[0].childNodes[0];
    };

    vm.readyNoPlayerHandler = function (chartWrapper) {
      vm.htmlNoPlayerTable = chartWrapper.getChart().getContainer().childNodes[0].childNodes[0].childNodes[0];
    };

    vm.readyTeamHandler = function (chartWrapper) {
      vm.htmlTeamTable = chartWrapper.getChart().getContainer().childNodes[0].childNodes[0].childNodes[0];
    };
  }
}());
